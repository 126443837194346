import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "infos" }
const _hoisted_2 = { class: "tops" }
const _hoisted_3 = { class: "titl" }
const _hoisted_4 = { class: "diw" }
const _hoisted_5 = { class: "numb" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_myhead = _resolveComponent("myhead")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_myhead, {
      sou: false,
      onPlay: _ctx.play
    }, null, 8, ["onPlay"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.info.post_title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.info.create_time), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.info.post_hits) + "人已看", 1)
      ])
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.info.post_content,
      class: "constent"
    }, null, 8, _hoisted_6),
    _createElementVNode("div", {
      class: "bnts",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back(-1)))
    }, "返回列表")
  ]))
}