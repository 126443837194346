
import {
  useRoute,
  useRouter
} from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import { defineComponent, reactive, onMounted, toRefs, ref } from 'vue'
import http from '@/api/index'
import { Howl, Howler } from 'howler'
// declare const Howl: any
export default defineComponent({
  setup () {
    const $route = useRoute()
    const $router = useRouter()
    const id = ref<string>('')
    const info = ref<any[]>([])
    let playIndex = 0
    const playerList:any = []
    const playing = ref<boolean>(false)
    let sounder:any = {}
    const geinfo = async () => {
      const data:any = await http.get('portal/articles/info', {
        id: id.value
      })
      if (data.code === 1) {
        info.value = data.data
        // if (data.data.more && data.data.more.audio && data.data.more.audio.length) {
        //   const audio = data.data.more.audio
        //   sound = new Howl({
        //     src: audio,
        //     autoplay: false,
        //     loop: true,
        //     volume: 0.5,
        //     onend: () => {
        //       console.log('Finished!')
        //     }
        //   })
        // }
        if (data.data.more && data.data.more.audio && data.data.more.audio.length) {
          const audio = data.data.more.audio
          console.log('ee video', audio)
          audio.forEach((o:string, i:number) => {
            initSound(audio, i)
          })
        }
      } else {
        Toast(data.msg)
      }
      console.log('eeeeevqwe', data)
    }
    const play = () => {
      console.log('play me')
      sounder = playerList[playIndex].howl
      if (playing.value) {
        sounder.stop()
        playing.value = false
      } else {
        sounder.play()
      }
    }
    const initSound = (urlList:Array<string>, _index:number) => {
      const _urlLen = urlList.length
      const _url = urlList[_index]
      if (_urlLen > 0) {
        const sound = new Howl({
          src: [_url],
          volume: 1
        })
        // 播放完事件监听
        sound.on('end', () => {
          // 最后一个停止播放，否则播放下一个
          if (playIndex === _urlLen - 1) {
            // this.stopAudio()
            playing.value = false
            sounder.stop()
          } else {
            console.log('播放下一个')
            playIndex += 1
            sounder = playerList[playIndex].howl
            sounder.play()
          }
        })
        sound.on('play', () => {
          playing.value = true
        })
        // 放入播放列表中
        playerList.push({
          howl: sound
        })
      }
    }
    onMounted(() => {
      id.value = $route.query.id + ''
      geinfo()
      const store = useStore()
      store.commit('setting', true)
    })
    return {
      id,
      $router,
      play,
      info
    }
  }
})
